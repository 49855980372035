








































import {Component, Prop, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import DataTable from "@/components/DataTable.vue";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    IFilteredQueryRequest,
    IPaginatedList
} from "@/api/Utils";
import Check from "@/components/helpers/Check.vue";
import Modal from "@/components/Modal.vue";
import Icon from "@/components/helpers/Icon.vue";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import Helper from "@/components/helpers/Helper.vue";
import {CarrierService, CarrierServiceApi} from "@/api/v1/CarrierServiceApi";
import {Getter} from "vuex-class";
import {TimestampCache} from "@/store/cache";

@Component({components: {DeleteIcon, UpdateIcon, DataTable, Check, Modal, Icon}})
export default class ManageCarrierServices extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly carrierId!: Guid;

    dataProvider: Promise<IPaginatedList<CarrierService>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest();
    @Getter tstpCache!: TimestampCache;

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("name"), key: "name", sortable: true},
            {label: this.$t("code"), key: "code", sortable: true},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    @Watch("tstpCache.carrierServices")
    refresh() {
        this.dataProvider = CarrierServiceApi.find(this.clientId, this.carrierId, this.query)
            .catch((err) => {
                if (err.status === 404) {
                    this.$router.replace({name: "carriers"});
                }

                throw err;
            });
    }

    deleteService(item: CarrierService) {
        CarrierServiceApi.delete(this.clientId, this.carrierId, item.id)
            .then(() => {
               this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-delete-carrier-service").toString(), ErrorToastSettings);

                throw err;
            });
    }

    create() {
        this.$router.push({name: "createCarrierService", params: {carrierId: this.carrierId.toString()}});
    }
}
