




import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ApexCharts from "apexcharts";
import {capitalise} from "@/api/Utils";

@Component
export default class SingleGroupChart extends Vue {
    @Prop({type: String, required: true}) readonly primary!: string;
    @Prop({type: Array, required: true}) readonly groups!: string[];
    @Prop({type: Array, required: true}) readonly data!: Array<Record<string, string | number>>;
    @Prop({type: String, required: true}) readonly type!: "pie" | "donut";
    @Prop({type: String, default: undefined}) readonly title?: string;

    chart: any = null;

    get apexOptions() {
        const options: Record<string, any> = {
            series: [],
            chart: {
                height: "100%",
                type: this.type.toLowerCase(),
                animations: {
                    enabled: false,
                },
            },
            labels: [],
            dataLabels: {
                formatter(value) {
                    return (Math.round(value as number * 10) / 10) + "%";
                },
            },
            title: {
                text: this.title,
                align: "center",
                style: {
                    fontSize: "16px"
                }
            },
            noData: {
                text: "No Data",
                align: "center",
                style: {
                    fontSize: "24px"
                }
            },
            legend: {
                position: "bottom"
            },
            colors: [],
        };

        if (!this.data.length) {
            return options;
        }

        if (this.groups.length === 0) {
            options.series.push(this.data[0]![this.primary]);
            options.labels.push(capitalise(this.primary));

            return options;
        }

        const firstGroupName = this.groups[0];
        const uniqueFirstGroupValues = Array.from(new Set(this.data.map((dataRow) => {
            return dataRow[firstGroupName] ?? "None";
        })));

        uniqueFirstGroupValues.forEach((value) => {
            options.labels.push(value.toString());
            const matchedMeasurement = this.data.find((dataRow) => (dataRow[firstGroupName] ?? "None") === value);
            options.series.push(matchedMeasurement![this.primary]);
        });

        // Generate a distinct and unique colour for each series
        for (let i = 0; i < options.series.length; i += 1) {
            const hue = i * Math.trunc(360 / options.series.length);
            options.colors.push(`hsl(${hue}, 70%, 50%)`);
        }

        return options;
    }

    @Watch("apexOptions")
    rerender(newOptions) {
        if (!newOptions || !this.$refs.chartElement) {
            return;
        }
        this.chart?.destroy();
        this.chart = new ApexCharts(this.$refs.chartElement, newOptions);
        this.chart.render();
    }

    mounted() {
        this.rerender(this.apexOptions);
    }

    beforeDestroy() {
        this.chart?.destroy();
    }
}
