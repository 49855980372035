


















































































































import {Component, Prop} from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import {Guid} from "guid-typescript";
import {Action} from "vuex-class";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";
import {emptySkuMap, SkuMap, SkuMapApi} from "@/api/v1/SkuMapApi";
import {OrderSourceType} from "@/api/v1/OrderSourceApi";
import Helper from "@/components/helpers/Helper.vue";

@Component({components: {Modal}})
export default class CreateSkuMap extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    @Action invalidateSkuMaps!: () => any;

    skuMap: SkuMap = emptySkuMap();
    OrderSourceType: typeof OrderSourceType = OrderSourceType;

    done() {
        this.$emit("done");
    }

    submit() {
        SkuMapApi.create(this.clientId, this.skuMap)
            .then(() => {
                this.$parent.$bvToast.toast(this.$t("sku-map-created").toString(), SuccessToastSettings);
                this.done();
                this.invalidateSkuMaps();
            })
            .catch(err => {
                this.$parent.$bvToast.toast(this.$t("failed-create-sku-map").toString(), ErrorToastSettings);

                throw err;
            });
    }
}
