




























































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import Modal from "@/components/Modal.vue";
import {
    AmazonMarketplace,
    emptyOrderSource,
    OnBuySite,
    OrderSource,
    OrderSourceApi,
    OrderSourceType
} from "@/api/v1/OrderSourceApi";
import {Action} from "vuex-class";
import {TranslateResult} from "vue-i18n";

@Component({
    computed: {
        OnBuySite() {
            return OnBuySite;
        }
    },
    components: {Modal}})
export default class CreateOrUpdateOrderSource extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: Number}) readonly type!: OrderSourceType;
    @Prop({type: String, default: null}) readonly orderSourceId!: Guid | null;

    @Action invalidateOrderSources!: () => any;

    orderSource: OrderSource = emptyOrderSource();

    shop: string | null = null;

    OrderSourceType: typeof OrderSourceType = OrderSourceType;
    AmazonMarketplace: typeof AmazonMarketplace = AmazonMarketplace;

    get secretKeyText(): TranslateResult {
        switch (this.orderSource.type) {
            case OrderSourceType.Ebay:
            case OrderSourceType.Shopify:
            case OrderSourceType.Ekm:
            case OrderSourceType.BigCommerce:
            case OrderSourceType.Squarespace:
            case OrderSourceType.Amazon:
            case OrderSourceType.Etsy:
                return this.$t("auth-code");
            case OrderSourceType.OnBuy:
                return this.$t("secret-key");
            case OrderSourceType.Woo:
                return this.$t("consumer-secret");
            case OrderSourceType.Custom:
                return "";
            default:
                return "ERROR";
        }
    }

    get isOauthSource(): boolean {
        return this.orderSource.type === OrderSourceType.Amazon || this.orderSource.type === OrderSourceType.Ebay
            || this.orderSource.type === OrderSourceType.Shopify || this.orderSource.type === OrderSourceType.Ekm
            || this.orderSource.type === OrderSourceType.BigCommerce || this.orderSource.type === OrderSourceType.Squarespace
            || this.orderSource.type === OrderSourceType.Etsy;
    }
    submit() {
        if (this.orderSourceId) {
            OrderSourceApi.update(this.clientId, this.orderSourceId, this.orderSource)
                .then(() => {
                    //TODO add toasts
                    this.$router.push({name: "orderSources", params: {clientId: this.clientId.toString()}});
                    this.invalidateOrderSources();
                });
        } else {
            OrderSourceApi.create(this.clientId, this.orderSource)
                .then(response => {
                    //TODO add toasts
                    if (this.isOauthSource) {
                        this.$router.push({
                            name: "authOrderSource",
                            params: {
                                clientId: this.clientId.toString()
                            },
                            query: {
                                orderSourceId: response.id.toString()
                            }
                        });
                    } else {
                        this.$router.push({
                            name: "orderSources",
                            params: {
                                clientId: this.clientId.toString()
                            }
                        });
                    }
                    this.invalidateOrderSources();
                });
        }
    }

    get disabledCreate(): boolean {
        return this.secretKeyText === "ERROR";
    }

    get typeText(): string {
        switch (this.type) {
            case OrderSourceType.Ebay:
                return "eBay";
            case OrderSourceType.Amazon:
                return "Amazon UK";
            case OrderSourceType.Woo:
                return "WooCommerce";
            case OrderSourceType.Etsy:
                return "Etsy";
            case OrderSourceType.OnBuy:
                return "OnBuy UK";
            case OrderSourceType.Shopify:
                return "Shopify";
            case OrderSourceType.Ekm:
                return "EKM";
            case OrderSourceType.Squarespace:
                return "Squarespace";
            case OrderSourceType.BigCommerce:
                return "BigCommerce";
            case OrderSourceType.Custom:
                return "Custom";
        }

        return "Unknown";
    }

    @Watch("orderSource.siteId")
    onMarketplaceUpdate() {
        switch (this.orderSource.siteId) {
            // Europe
            case AmazonMarketplace.UK:
                this.orderSource.name = "Amazon UK";
                break;
            case AmazonMarketplace.France:
                this.orderSource.name = "Amazon France";
                break;
            case AmazonMarketplace.Germany:
                this.orderSource.name = "Amazon Germany";
                break;
            case AmazonMarketplace.Italy:
                this.orderSource.name = "Amazon Italy";
                break;
            case AmazonMarketplace.Spain:
                this.orderSource.name = "Amazon Spain";
                break;
            case AmazonMarketplace.Netherlands:
                this.orderSource.name = "Amazon Netherlands";
                break;
            case AmazonMarketplace.Sweden:
                this.orderSource.name = "Amazon Sweden";
                break;
            case AmazonMarketplace.Turkey:
                this.orderSource.name = "Amazon Turkey";
                break;
            case AmazonMarketplace.UAE:
                this.orderSource.name = "Amazon UAE";
                break;
            case AmazonMarketplace.India:
                this.orderSource.name = "Amazon India";
                break;
            // North America
            case AmazonMarketplace.US:
                this.orderSource.name = "Amazon US";
                break;
            case AmazonMarketplace.Canada:
                this.orderSource.name = "Amazon Canada";
                break;
            case AmazonMarketplace.Mexico:
                this.orderSource.name = "Amazon Mexico";
                break;
            case AmazonMarketplace.Brazil:
                this.orderSource.name = "Amazon Brazil";
                break;
            // Far East
            case AmazonMarketplace.Singapore:
                this.orderSource.name = "Amazon Singapore";
                break;
            case AmazonMarketplace.Australia:
                this.orderSource.name = "Amazon Australia";
                break;
            case AmazonMarketplace.Japan:
                this.orderSource.name = "Amazon Japan";
                break;
            case OnBuySite.UK:
                this.orderSource.name = "OnBuy UK";
                break;
            case OnBuySite.France:
                this.orderSource.name = "OnBuy France";
                break;
            case OnBuySite.Germany:
                this.orderSource.name = "OnBuy Germany";
                break;
        }
    }

    created() {
        if (this.orderSourceId) {
            OrderSourceApi.findById(this.clientId, this.orderSourceId)
                .then(data => {
                    this.orderSource = data;
                });
        } else {
            this.orderSource.type = this.type;
            this.orderSource.name = this.typeText;
            if (this.orderSource.type === OrderSourceType.Ebay) {
                OrderSourceApi.getAuthSettings(this.clientId, this.type)
                    .then(settings => {
                        this.orderSource.sandbox = settings.ebay.sandbox;
                    });
            } else if (this.orderSource.type == OrderSourceType.Amazon) {
                this.orderSource.siteId = AmazonMarketplace.UK;
            } else if (this.orderSource.type == OrderSourceType.OnBuy) {
                this.orderSource.siteId = OnBuySite.UK;
            }
        }
    }
}
