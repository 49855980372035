




























































































import {Component, Prop, Vue} from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import {Guid} from "guid-typescript";
import {Action} from "vuex-class";
import {Carrier, CarrierApi, CarrierType, emptyCarrier} from "@/api/v1/CarrierApi";
import {TranslateResult} from "vue-i18n";

@Component({components: {Modal}})
export default class CreateOrUpdateCarrier extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: Number}) readonly type!: CarrierType;
    @Prop({type: String, default: null}) readonly carrierId!: Guid | null;

    @Action invalidateCarriers!: () => any;

    carrier: Carrier = emptyCarrier();

    CarrierType: typeof CarrierType = CarrierType;

    get secretKeyText(): TranslateResult {
        switch (this.carrier.type) {
            case CarrierType.ClickAndDrop:
                return this.$t("api-key");
            case CarrierType.TransglobalExpress:
                return this.$t("api-password");
            default:
                return "ERROR";
        }
    }

    get isManual(): boolean {
        return this.carrier.type === CarrierType.AddressLabelOnly || this.carrier.type === CarrierType.Custom;
    }

    get isOAuth(): boolean {
        switch (this.carrier.type) {
            case CarrierType.Parcel2Go:
                return true;
            default:
                return false;
        }
    }

    submit() {
        if (this.carrierId) {
            CarrierApi.update(this.clientId, this.carrierId, this.carrier)
                .then(() => {
                    //TODO add toasts
                    this.$router.push({name: "carriers", params: {clientId: this.clientId.toString()}});
                    this.invalidateCarriers();
                });
        } else {
            CarrierApi.create(this.clientId, this.carrier)
                .then(response => {
                    if (this.isOAuth) {
                        this.$router.push({
                            name: "authCarrier",
                            params: {
                                clientId: this.clientId.toString()
                            },
                            query: {
                                carrierId: response.id.toString()
                            }
                        });
                    } else if (this.carrier.type === CarrierType.ClickAndDrop) {
                        this.$router.push({
                            name: "manageCarrierServices",
                            params: {
                                clientId: this.clientId.toString(),
                                carrierId: response.id.toString(),
                            }
                        });
                    } else {
                        this.$router.push({
                            name: "carriers",
                            params: {
                                clientId: this.clientId.toString()
                            }
                        });
                    }

                    this.invalidateCarriers();
                });
        }
    }

    get typeText(): string {
        switch (this.type) {
            case CarrierType.AddressLabelOnly:
                return "Address Label Only";
            case CarrierType.Custom:
                return "Custom";
            case CarrierType.Parcel2Go:
                return "Parcel2Go";
            case CarrierType.ClickAndDrop:
                return "Click & Drop";
            case CarrierType.TransglobalExpress:
                return "Transglobal Express";
        }

        return "Unknown";
    }

    created() {
        if (this.carrierId) {
            CarrierApi.findById(this.clientId, this.carrierId)
                .then(data => {
                    //TODO add toast in catch
                    this.carrier = data;
                });
        } else {
            this.carrier.type = this.type;
            this.carrier.name = this.typeText;
            CarrierApi.getAuthSettings(this.clientId)
                .then(settings => {
                    if (this.carrier.type === CarrierType.Parcel2Go) this.carrier.sandbox = settings.p2g.sandbox;
                });
        }
    }
}
