




















































import {Component, Prop} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {emptyTemplate, Template, TemplateApi, TemplateType} from "@/api/v1/TemplateApi";
import Modal from "@/components/Modal.vue";
import {Action} from "vuex-class";
import Wysiwyg from "@/components/helpers/Wysiwyg.vue";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";
import Helper from "@/components/helpers/Helper.vue";

@Component({
    components: {Wysiwyg, Modal}
})
export default class CreateOrUpdateTemplate extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, default: null}) readonly templateId!: Guid | null;
    @Prop({type: Boolean, default: false}) readonly shareable!: boolean;

    template: Template = emptyTemplate(TemplateType.AddressLabel);
    Type: typeof TemplateType = TemplateType;

    @Action invalidateTemplates!: () => any;

    done() {
        this.$emit("done");
    }

    submit() {
        if (this.templateId) {
            TemplateApi.update(this.clientId, this.templateId, this.template)
                .then(() => {
                    this.$parent.$bvToast.toast(this.$t("template-updated").toString(), SuccessToastSettings);
                    this.done();
                    this.invalidateTemplates();
                })
                .catch(err => {
                    this.$parent.$bvToast.toast(this.$t("failed-update-template").toString(), ErrorToastSettings);

                    throw err;
                });
        } else {
            TemplateApi.create(this.clientId, this.template)
                .then(() => {
                    this.$parent.$bvToast.toast(this.$t("template-created").toString(), SuccessToastSettings);
                    this.done();
                    this.invalidateTemplates();
                })
                .catch(err => {
                    this.$parent.$bvToast.toast(this.$t("failed-create-template").toString(), ErrorToastSettings);

                    throw err;
                });
        }
    }

    mounted() {
        this.template.shareable = this.shareable;
        if (this.templateId) {
            TemplateApi.findById(this.clientId, this.templateId)
                .then(data => {
                    this.template = data;
                })
                .catch(err => {
                    if (err.status === 404) {
                        this.done();
                    } else {
                        this.$parent.$bvToast.toast(this.$t("failed-retrieve-template").toString(), ErrorToastSettings);
                        throw err;
                    }
                });
        }
    }
}
