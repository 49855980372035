





































































import {Component} from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import {CarrierType} from "@/api/v1/CarrierApi";
import Helper from "@/components/helpers/Helper.vue";

@Component({components: {Modal}})
export default class SelectCarrierType extends Helper {
    type: CarrierType = CarrierType.Parcel2Go;
    CarrierType: typeof CarrierType = CarrierType;

    submit() {
        this.$router.push({name: "createCarrier", params: {type: this.type.valueOf().toString()}});
    }

    done() {
        this.$emit("done");
    }
}
