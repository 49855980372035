






































































import {Component, Prop} from "vue-property-decorator";
import CountrySelect from "@/components/helpers/CountrySelect.vue";
import {
    ClientShippingInfo,
    ClientShippingInfoApi,
    emptyClientShippingInfo,
    ExportReason,
    VatStatus
} from "@/api/v1/ClientShippingInfoApi";
import {Guid} from "guid-typescript";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";
import Icon from "@/components/helpers/Icon.vue";
import Helper from "@/components/helpers/Helper.vue";

@Component({
    components: {CountrySelect, Icon}
})
export default class ManageShippingInfo extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    clientShippingInfo: ClientShippingInfo = emptyClientShippingInfo();
    ExportReason: typeof ExportReason = ExportReason;
    VatStatus: typeof VatStatus = VatStatus;

    get isValidVatNumber(): boolean | null {
        let valid: boolean | null = null;
        if (!this.clientShippingInfo.vatNumber) return null;

        const regex = RegExp(/^([GB])*(([1-9]\d{8})|([1-9]\d{11}))$/);
        valid = regex.test(this.clientShippingInfo.vatNumber);

        return valid;
    }

    submit() {
        ClientShippingInfoApi.set(this.clientId, this.clientShippingInfo)
            .then(() => {
                this.$bvToast.toast("Successfully updated", SuccessToastSettings);
            })
            .catch(err => {
                this.$bvToast.toast("Failed to update", ErrorToastSettings);

                throw err;
            });
    }

    created() {
        ClientShippingInfoApi.get(this.clientId)
            .then(data => {
                if ((data as any).status !== 204) {
                    this.clientShippingInfo = data;
                }
            });
    }
}
