










































































import {Component, Prop, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import DataTable from "@/components/DataTable.vue";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    IFilteredQueryRequest,
    IPaginatedList,
    SuccessToastSettings
} from "@/api/Utils";
import {Carrier, CarrierApi, CarrierType} from "@/api/v1/CarrierApi";
import {Getter} from "vuex-class";
import Check from "@/components/helpers/Check.vue";
import Modal from "@/components/Modal.vue";
import Icon from "@/components/helpers/Icon.vue";
import {TimestampCache} from "@/store/cache";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import Helper from "@/components/helpers/Helper.vue";

@Component({components: {DeleteIcon, UpdateIcon, DataTable, Check, Modal, Icon}})
export default class CarrierTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    dataProvider: Promise<IPaginatedList<any>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest();
    deleting: Carrier | null = null;

    CarrierType: typeof CarrierType = CarrierType;

    @Getter tstpCache!: TimestampCache;

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("name"), key: "name", sortable: true},
            {label: this.$t("type"), key: "type", sortable: true},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    isOAuth(type: CarrierType): boolean {
        switch (type) {
            case CarrierType.Parcel2Go:
                return true;
            default:
                return false;
        }
    }

    reauthTo(id: Guid) {
        return {
            name: "authCarrier",
            params: {
                clientId: this.clientId.toString()
            },
            query: {
                carrierId: id.toString()
            }
        };
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    @Watch("tstpCache.carriers")
    refresh() {
        this.dataProvider = CarrierApi.find(this.clientId, this.query);
    }

    create() {
        this.$router.push({name: "selectCarrierType"});
    }

    confirmDelete(item: Carrier) {
        this.deleting = item;
    }

    deleteCarrier() {
        if (this.deleting) {
            CarrierApi.delete(this.clientId, this.deleting.id)
                .then(() => {
                    this.$bvToast.toast(this.$t("carrier-deleted").toString(), SuccessToastSettings);
                    this.deleting = null;
                    this.refresh();
                })
                .catch(err => {
                    this.$bvToast.toast(this.$t("failed-delete-carrier").toString(), ErrorToastSettings);

                    throw err;
                });
        }
    }

    closeModal() {
        this.deleting = null;
    }
}
