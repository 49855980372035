import {IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";
import {deleteRequest, getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {Guid} from "guid-typescript";

export const CarrierServiceApi = {
    create(clientId: Guid, carrierId: Guid, service: CarrierService): Promise<CarrierService> {
        return postRequest(getBaseUrl(clientId, carrierId), service);
    },
    find(clientId: Guid, carrierId: Guid, queryRequest: IFilteredQueryRequest): Promise<IPaginatedList<CarrierService>> {
        return getRequest(getBaseUrl(clientId, carrierId), queryRequest);
    },
    findById(clientId: Guid, carrierId: Guid, id: Guid): Promise<CarrierService> {
        return getRequest(`${getBaseUrl(clientId, carrierId)}/${id}`);
    },
    update(clientId: Guid, carrierId: Guid, id: Guid, service: CarrierService): Promise<void> {
        return putRequest(`${getBaseUrl(clientId, carrierId)}/${id}`, service);
    },
    delete(clientId: Guid, carrierId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId, carrierId)}/${id}`);
    },
};

function getBaseUrl(clientId: Guid, carrierId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/carriers/${carrierId}/services`;
}

export interface CarrierService {
    id: Guid;
    name: string;
    code: string;
}

export function emptyCarrierService(): CarrierService {
    return {
        id: Guid.createEmpty(),
        name: "",
        code: ""
    };
}

