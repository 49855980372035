














































































































import {Component} from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import {OrderSourceType} from "@/api/v1/OrderSourceApi";
import Helper from "@/components/helpers/Helper.vue";

@Component({components: {Modal}})
export default class SelectOrderSourceType extends Helper {
    type: OrderSourceType = OrderSourceType.Ebay;

    OrderSourceType: typeof OrderSourceType = OrderSourceType;

    submit() {
        this.$router.push({name: "createOrderSource", params: {type: this.type.valueOf().toString()}});
    }

    done() {
        this.$emit("done");
    }
}
