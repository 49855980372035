





































import {Component, Prop} from "vue-property-decorator";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import DataTable from "@/components/DataTable.vue";
import Check from "@/components/helpers/Check.vue";
import Modal from "@/components/Modal.vue";
import Icon from "@/components/helpers/Icon.vue";
import Helper from "@/components/helpers/Helper.vue";
import {Guid} from "guid-typescript";
import {CarrierService, CarrierServiceApi, emptyCarrierService} from "@/api/v1/CarrierServiceApi";
import {Action} from "vuex-class";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";

@Component({components: {DeleteIcon, UpdateIcon, DataTable, Check, Modal, Icon}})
export default class CreateOrUpdateCarrierService extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly carrierId!: Guid;
    @Prop({type: String, default: null}) readonly serviceId!: Guid | null;

    service: CarrierService = emptyCarrierService();

    @Action invalidateCarrierServices!: Function;

    done() {
        this.$emit("done");
    }

    submit() {
        if (this.serviceId) {
            CarrierServiceApi.update(this.clientId, this.carrierId, this.serviceId, this.service)
                .then(() => {
                    this.$parent.$bvToast.toast(this.$t("carrier-service-updated").toString(), SuccessToastSettings);
                    this.invalidateCarrierServices();
                    this.done();
                })
                .catch(err => {
                    this.$bvToast.toast(this.$t("failed-update-carrier-service").toString(), ErrorToastSettings);

                    throw err;
                });
        } else {
            CarrierServiceApi.create(this.clientId, this.carrierId, this.service)
                .then(() => {
                    this.$parent.$bvToast.toast(this.$t("carrier-service-created").toString(), SuccessToastSettings);
                    this.invalidateCarrierServices();
                    this.done();
                })
                .catch(err => {
                    this.$bvToast.toast(this.$t("failed-create-carrier-service").toString(), ErrorToastSettings);

                    throw err;
                });
        }
    }

    created() {
        if (this.serviceId) {
            CarrierServiceApi.findById(this.clientId, this.carrierId, this.serviceId)
                .then(data => {
                    this.service = data;
                });
        }
    }

}
