








































import {Component, Prop, Vue} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import Modal from "@/components/Modal.vue";
import {Action} from "vuex-class";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";
import {ApiToken, emptyApiToken, TokenApi} from "@/api/v1/TokenApi";

@Component({components: {Modal}})
export default class CreateOrUpdateApiToken extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, default: null}) readonly tokenId!: Guid | null;

    @Action invalidateApiTokens!: () => any;

    apiToken: ApiToken = emptyApiToken();

    secret: string | null = null;

    done() {
        this.$emit("done");
    }

    submit() {
        if (this.tokenId) {
            TokenApi.update(this.clientId, this.tokenId, this.apiToken)
                .then(() => {
                    this.$parent.$bvToast.toast(this.$t("api-token-updated").toString(), SuccessToastSettings);
                    this.done();
                    this.invalidateApiTokens();
                })
                .catch(err => {
                    this.$parent.$bvToast.toast(this.$t("failed-update-api-token").toString(), ErrorToastSettings);

                    throw err;
                });
        } else {
            if (this.secret) {
                this.$parent.$bvToast.toast(this.$t("api-token-created").toString(), SuccessToastSettings);
                this.done();
                this.invalidateApiTokens();
            } else {
                TokenApi.create(this.clientId, this.apiToken)
                    .then(data => {
                        this.secret = data.secret;
                    })
                    .catch(err => {
                        this.$parent.$bvToast.toast(this.$t("failed-create-api-token").toString(), ErrorToastSettings);

                        throw err;
                    });
            }
        }
    }

    created() {
        if (this.tokenId) {
            TokenApi.findById(this.clientId, this.tokenId)
                .then(data => {
                    this.apiToken = data;
                });
        }
    }
}
