






















import {Component, Prop, Vue} from "vue-property-decorator";
import SingleGroupChart from "@/components/SingleGroupChart.vue";
import DoubleGroupChart from "@/components/DoubleGroupChart.vue";

@Component({
    components: {DoubleGroupChart, SingleGroupChart}
})
export default class Chart extends Vue {
    @Prop({type: String, required: true}) readonly primary!: string;
    @Prop({type: Array, required: true}) readonly data!: Array<Record<string, string | number>>;
    @Prop({type: Array, required: true}) readonly groups!: string[];
    @Prop({type: Array, default: () => ([])}) readonly dateFields!: string[];
    @Prop({type: String, required: true}) readonly type!: "bar" | "line" | "area" | "pie" | "donut";
    @Prop({type: String, default: undefined}) readonly title?: string;

    get sortedGroups() {
        return this.groups.sort((a, b) => {
            // dates first, then non-dates
            if (this.dateFields.includes(a)) {
                return -1;
            }
            if (this.dateFields.includes(b)) {
                return 1;
            }
            return 0;
        });
    }

    get singleGroup() {
        return this.sortedGroups.slice(0, 1);
    }

    get twoGroups() {
        return this.sortedGroups.slice(0, 2);
    }
}
