








































import {Component, Prop} from "vue-property-decorator";
import RouterTabs from "@/components/RouterTabs.vue";
import {format, startOfMonth} from "date-fns";
import {Guid} from "guid-typescript";
import {MetricsApi} from "@/api/v1/MetricsApi";
import fileSize from "filesize";
import Helper from "@/components/helpers/Helper.vue";

@Component({
    components: {RouterTabs}
})
export default class Usages extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    ordersUsage: number | null = null;
    orderFilesUsage: number | null = null;
    ordersMax = 1500;
    orderFilesMax = 524_288_000;

    get startOfPeriod(): string {
        return format(startOfMonth(new Date()), "MMM dd, y");
    }

    get orderFilesUsageReadable() {
        return fileSize(this.orderFilesUsage || 0);
    }

    get orderFilesMaxUsageReadable() {
        return fileSize(this.orderFilesMax);
    }

    async created() {
        this.ordersUsage = await MetricsApi.getOrdersUsage(this.clientId);
        if (this.featureCheck("order-files"))
            this.orderFilesUsage = await MetricsApi.getOrderFilesUsage(this.clientId);
    }
}
