











































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {ErrorToastSettings} from "@/api/Utils";
import {
    AmazonMarketplace,
    emptyOrderSource,
    emptyOrderSourceAuthSettings,
    OrderSource,
    OrderSourceApi,
    OrderSourceAuthSettings,
    OrderSourceType
} from "@/api/v1/OrderSourceApi";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;

@Component
export default class AuthOrderSource extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, default: null}) readonly orderSourceId!: Guid | null;

    orderSource: OrderSource = emptyOrderSource();
    authSettings: OrderSourceAuthSettings = emptyOrderSourceAuthSettings();

    inProgress = false;

    OrderSourceType: typeof OrderSourceType = OrderSourceType;

    stateKey = "order-source_auth_state";

    get codeText(): TranslateResult {
        return this.$t("auth-code");
    }

    get authoriseUrl(): string {
        let result = "";
        if (this.orderSourceId) {
            const startOfUrl = `${location.protocol}//${location.hostname}${location.port ? ":" + location.port : ""}`;
            const redirectUri = `${encodeURI(`${startOfUrl}/settings/order-sources/auth`)}`;

            const stateObject: State = {
                orderSourceId: this.orderSourceId,
                nonce: Guid.create().toString()
            };

            const state = stateObject.nonce;
            window.localStorage.setItem(this.stateKey, JSON.stringify(stateObject));

            if (this.orderSource.type === OrderSourceType.Amazon) {
                if (this.authSettings.amazon.clientId !== "") {
                    let domain = "";

                    switch (this.orderSource.siteId) {
                        case AmazonMarketplace.UK:
                        case AmazonMarketplace.France:
                        case AmazonMarketplace.Germany:
                        case AmazonMarketplace.Italy:
                        case AmazonMarketplace.Spain:
                            domain = "sellercentral-europe.amazon.com";
                            break;
                        case AmazonMarketplace.Netherlands:
                            domain = "sellercentral.amazon.nl";
                            break;
                        case AmazonMarketplace.Sweden:
                            domain = "sellercentral.amazon.se";
                            break;
                        case AmazonMarketplace.Turkey:
                            domain = "sellercentral.amazon.com.tr";
                            break;
                        case AmazonMarketplace.UAE:
                            domain = "sellercentral.amazon.ae";
                            break;
                        case AmazonMarketplace.India:
                            domain = "sellercentral.amazon.in";
                            break;
                        case AmazonMarketplace.US:
                            domain = "sellercentral.amazon.com";
                            break;
                        case AmazonMarketplace.Canada:
                            domain = "sellercentral.amazon.ca";
                            break;
                        case AmazonMarketplace.Mexico:
                            domain = "sellercentral.amazon.com.mx";
                            break;
                        case AmazonMarketplace.Brazil:
                            domain = "sellercentral.amazon.com.br";
                            break;
                        case AmazonMarketplace.Singapore:
                            domain = "sellercentral.amazon.sg";
                            break;
                        case AmazonMarketplace.Australia:
                            domain = "sellercentral.amazon.com.au";
                            break;
                        case AmazonMarketplace.Japan:
                            domain = "sellercentral.amazon.co.jp";
                            break;
                    }

                    const baseUrl =
                        `https://${domain}/apps/authorize/consent`;

                    const params = new URLSearchParams();
                    params.append("application_id", this.authSettings.amazon.clientId);
                    params.append("state", state);
                    if (this.authSettings.amazon.sandbox) {
                        params.append("version", "beta");
                    }

                    result = `${baseUrl}?${params.toString()}`;
                }
            } else if (this.orderSource.type === OrderSourceType.Ebay) {
                if (this.authSettings.ebay.ruName !== "") {
                    const baseUrl =
                        `https://auth.${this.authSettings.ebay.sandbox ? "sandbox." : ""}ebay.com/oauth2/authorize`;

                    const params = new URLSearchParams();
                    params.append("response_type", "code");
                    params.append("scope", this.authSettings.ebay.scopes);
                    params.append("client_id", this.authSettings.ebay.appId);
                    params.append("state", state);
                    params.append("redirect_uri", this.authSettings.ebay.ruName);

                    result = `${baseUrl}?${params.toString()}`;
                }
            } else if (this.orderSource.type === OrderSourceType.Etsy) {
                if (this.authSettings.etsy.clientId !== "" && this.authSettings.etsy.codeChallenge) {
                    const baseUrl = "https://www.etsy.com/oauth/connect";

                    const params = new URLSearchParams();
                    params.append("response_type", "code");
                    params.append("scope", this.authSettings.etsy.scopes);
                    params.append("client_id", this.authSettings.etsy.clientId);
                    params.append("state", state);
                    params.append("redirect_uri", redirectUri);
                    params.append("code_challenge", this.authSettings.etsy.codeChallenge);
                    params.append("code_challenge_method", "S256");

                    result = `${baseUrl}?${params.toString()}`;
                }
            } else if (this.orderSource.type === OrderSourceType.Shopify) {
                if (this.authSettings.shopify.clientId !== "") {
                    const baseUrl =
                        `https://${this.orderSource.shopIdentifier}.myshopify.com/admin/oauth/authorize`;

                    const params = new URLSearchParams();
                    params.append("response_type", "code");
                    params.append("scope", this.authSettings.shopify.scopes);
                    params.append("client_id", this.authSettings.shopify.clientId);
                    params.append("state", state);
                    params.append("redirect_uri", redirectUri);

                    result = `${baseUrl}?${params.toString()}`;
                }
            } else if (this.orderSource.type === OrderSourceType.Ekm) {
                if (this.authSettings.ekm.clientId !== "") {
                    const baseUrl =
                        "https://api.ekm.net/connect/authorize";

                    const params = new URLSearchParams();
                    params.append("response_type", "code");
                    params.append("scope", this.authSettings.ekm.scopes);
                    params.append("client_id", this.authSettings.ekm.clientId);
                    params.append("state", state);
                    params.append("redirect_uri", redirectUri);

                    result = `${baseUrl}?${params.toString()}`;
                }
            }  else if (this.orderSource.type === OrderSourceType.Squarespace) {
                if (this.authSettings.squarespace.clientId !== "") {
                    const baseUrl =
                        "https://login.squarespace.com/api/1/login/oauth/provider/authorize";

                    const params = new URLSearchParams();
                    params.append("response_type", "code");
                    params.append("scope", this.authSettings.squarespace.scopes);
                    params.append("client_id", this.authSettings.squarespace.clientId);
                    params.append("access_type", "offline");
                    params.append("state", state);
                    params.append("redirect_uri", redirectUri);

                    result = `${baseUrl}?${params.toString()}`;
                }
            } else if (this.orderSource.type === OrderSourceType.BigCommerce) {
                if (this.authSettings.bigCommerce.clientId !== "") {
                    const baseUrl = `https://login.bigcommerce.com/app/${this.authSettings.bigCommerce.clientId}/install`;
                    const params = new URLSearchParams();
                    params.append("state", state);

                    result = `${baseUrl}?${params.toString()}`;
                }
            } else if (this.orderSource.type === -1) {
                //Do nothing
            } else {
                this.$router.push({name: "orderSources"});
            }
        }
        return result;
    }

    async created() {
        let orderSourceId: Guid | null = this.orderSourceId;
        const params = this.$route.query;
        if (!orderSourceId) {
            const stateParam = params["state"] as string | (string | null)[] | undefined;
            let nonce = "";
            const storedStateString = window.localStorage.getItem(this.stateKey);
            if (storedStateString) {
                window.localStorage.removeItem(this.stateKey);
                const storedState = JSON.parse(storedStateString) as State;
                if (stateParam !== "" && stateParam !== [] && stateParam !== undefined) {
                    nonce = stateParam as string;
                }

                if (nonce && storedState.nonce && nonce === storedState.nonce) {
                    orderSourceId = storedState.orderSourceId;
                }
            }
        }
        if (orderSourceId) {
            try {
                this.orderSource = await OrderSourceApi.findById(this.clientId, orderSourceId);
                const codeKey = this.orderSource.type === OrderSourceType.Amazon ? "spapi_oauth_code" : "code";
                const code = params[codeKey] as string | (string | null)[] | undefined;
                if (code !== "" && code !== [] && code !== undefined) {
                    // const scope = decodeURIComponent(params["scope"] as string);
                    //TODO validate scope

                    this.orderSource.secret = code as string;
                }

                if (this.orderSource.secret) {
                    const authData: any = {
                        code: this.orderSource.secret
                    };

                    if (this.orderSource.type === OrderSourceType.Shopify) {
                        const shopUrl = params["shop"] as string;
                        authData.shop = shopUrl.split(".")[0];

                        authData.hmacChallenge = window.location.search;
                    }

                    authData.type = this.orderSource.type;
                    this.inProgress = true;
                    OrderSourceApi.auth(this.clientId, orderSourceId, authData)
                        .then(() => {
                            this.inProgress = false;
                            this.$router.push({name: "orderSources"});
                        })
                        .catch(err => {
                            this.$parent.$bvToast.toast(this.$t("failed-authorise").toString(), ErrorToastSettings);
                            this.inProgress = false;

                            throw err;
                        });
                } else {
                    this.authSettings = await OrderSourceApi.getAuthSettings(this.clientId, this.orderSource.type);
                }
            } catch (err) {
                this.$parent.$bvToast.toast(this.$t("failed-authorise").toString(), ErrorToastSettings);
                await this.$router.push({name: "orderSources"});

                throw err;
            }
        } else {
            if (!this.orderSourceId) {
                this.$parent.$bvToast.toast(this.$t("failed-authorise").toString(), ErrorToastSettings);
            }
            await this.$router.push({name: "orderSources"});
        }
    }
}

interface State {
    orderSourceId: Guid;
    nonce: string;
}
