







import {Component} from "vue-property-decorator";
import {ITab} from "@/api/IRouterTab";
import RouterTabs from "@/components/RouterTabs.vue";
import {Getter} from "vuex-class";
import {UserIdentity} from "@/store/types";
import Helper from "@/components/helpers/Helper.vue";

@Component({
    components: {RouterTabs}
})
export default class Settings extends Helper {
    @Getter getIdentity!: UserIdentity;

    get tabs(): ITab[] {
        const tabs: ITab[] = [];
        if (this.permissionCheck("template:read")) {
            tabs.push({
                title: this.$t("templates"),
                to: "templates"
            });
        }
        if (this.permissionCheck("address:read")) {
            tabs.push({
                title: this.$t("addresses"),
                to: "addresses"
            });
        }
        if (this.permissionCheck("order-source:read")) {
            tabs.push({
                title: this.$t("order-sources"),
                to: "orderSources"
            });
        }
        if (this.permissionCheck("carrier-account:read")) {
            tabs.push({
                title: this.$t("carrier-accounts"),
                to: "carriers"
            });
        }
        if (this.permissionCheck("sku-map:read")) {
            tabs.push({
                title: this.$t("sku-maps"),
                to: "skuMaps"
            });
        }
        if (this.permissionCheck("courier-map:read")) {
            tabs.push({
                title: this.$t("courier-maps"),
                to: "courierMaps"
            });
        }
        if (this.permissionCheck("client-shipping-info:read")) {
            tabs.push({
                title: this.$t("shipping-information"),
                to: "shippingInfo"
            });
        }
        if (this.permissionCheck("api-token:read")) {
            tabs.push({
                title: this.$t("api-tokens"),
                to: "apiTokens"
            });
        }
        if (this.permissionCheck("metrics:read")) {
            tabs.push({
                title: this.$t("usages"),
                to: "usages"
            });
        }
        if (this.permissionCheck("settings:write")) {
            tabs.push({
                title: this.$t("features"),
                to: "settingsFlags"
            });
        }

        return tabs;
    }

    created() {
        if (this.$route.name === "settings") {
            if (this.permissionCheck("template:read")) {
                this.$router.replace({name: "templates"});
            } else if (this.permissionCheck("address:read")) {
                this.$router.replace({name: "addresses"});
            } else if (this.permissionCheck("order-source:read")) {
                this.$router.replace({name: "orderSources"});
            } else if (this.permissionCheck("carrier-account:read")) {
                this.$router.replace({name: "carriers"});
            } else if (this.permissionCheck("sku-map:read")) {
                this.$router.replace({name: "skuMaps"});
            } else if (this.permissionCheck("courier-map:read")) {
                this.$router.replace({name: "courierMaps"});
            } else if (this.permissionCheck("client-shipping-info:read")) {
                this.$router.replace({name: "shippingInfo"});
            } else if (this.permissionCheck("api-token:read")) {
                this.$router.replace({name: "apiTokens"});
            }
        }
    }
}
