






















import {Component, Prop, Vue} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {ChartType, MetricsApi} from "@/api/v1/MetricsApi";
import Chart from "@/components/Chart.vue";
import DateInput from "@/components/DateInput.vue";

@Component({components: {DateInput, Chart}})
export default class Metrics extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    charts: any[] = [];
    startDate: string | null = new Date("2021-01-01").toISOString();
    endDate: string | null = new Date().toISOString();
    startDateReset: string = new Date("2021-01-01").toISOString();

    async refresh() {
        this.charts = [];
        this.charts.push(await MetricsApi.getChart(this.clientId, this.startDate, this.endDate, {
            title: "Orders per day of week",
            measurement: "orders_count",
            primaryGroup: "order_placed_day_name",
            type: ChartType.Pie,
        }));
        this.charts.push(await MetricsApi.getChart(this.clientId, this.startDate, this.endDate, {
            title: "Orders per month",
            measurement: "orders_count",
            primaryGroup: "order_placed_month_name",
            secondaryGroup: "order_placed_year",
            type: ChartType.Bar,
        }));
        this.charts.push(await MetricsApi.getChart(this.clientId, this.startDate, this.endDate, {
            title: "Orders per year",
            measurement: "orders_count",
            primaryGroup: "order_placed_year",
            type: ChartType.Bar,
        }));
        this.charts.push(await MetricsApi.getChart(this.clientId, this.startDate, this.endDate, {
            title: "Orders per source",
            measurement: "orders_count",
            primaryGroup: "order_sources_name",
            type: ChartType.Pie,
        }));
        this.charts.push(await MetricsApi.getChart(this.clientId, this.startDate, this.endDate, {
            title: "Orders per source type",
            measurement: "orders_count",
            primaryGroup: "order_sources_type",
            type: ChartType.Pie,
        }));
    }

    async created() {
        await this.refresh();
    }
}
