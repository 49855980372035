
































import {Component, Prop, Vue} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import Icon from "@/components/helpers/Icon.vue";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";
import {ISettingsObject, ISettingsStatus, SettingsApi} from "@/api/v1/SettingsApi";

@Component({components: {Icon}})
export default class ManageSettings extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    availableFlags: {
        [name: string]: ISettingsObject;
    } = {};
    clientFlags: {
        [name: string]: ISettingsStatus;
    } = {};

    enabledChecked(key: string): boolean {
        return this.clientFlags[key]?.enabled ?? this.availableFlags[key]?.status.enabled;
    }

    refresh() {
        this.$store.dispatch("retrieveSettings", this.clientId);

        SettingsApi.get(this.clientId)
            .then(value => {
                this.availableFlags = value.available;
                this.clientFlags = value.client;
            })
            .catch(err => {
                this.$bvToast.toast("Failed to load", ErrorToastSettings);

                throw err;
            });
    }

    save() {
        SettingsApi.set(this.clientId, this.clientFlags)
            .then(() => {
                this.refresh();
                this.$bvToast.toast("Successfully updated", SuccessToastSettings);
            })
            .catch(err => {
                this.$bvToast.toast("Failed to update", ErrorToastSettings);

                throw err;
            });
    }

    setEnable(featureKey: string, value: boolean) {
        let clientFlag = this.clientFlags[featureKey];

        if (clientFlag === undefined) {
            clientFlag = {
                enabled: value
            };
            this.$set(this.clientFlags, featureKey, clientFlag);
        } else {
            clientFlag.enabled = value;
            this.$set(this.clientFlags, featureKey, clientFlag);
        }
    }

    created() {
        this.refresh();
    }
}
