

















































import {Component, Prop, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {Guid} from "guid-typescript";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    FilterOperand,
    IFilteredQueryRequest,
    IPaginatedList,
    SuccessToastSettings
} from "@/api/Utils";
import {Template, TemplateApi, TemplateType} from "@/api/v1/TemplateApi";
import {TimestampCache} from "@/store/cache";
import {Getter} from "vuex-class";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import Icon from "@/components/helpers/Icon.vue";
import Modal from "@/components/Modal.vue";
import {openBlobInNewTab} from "@/api/v1/ParcelShippingApi";
import Helper from "@/components/helpers/Helper.vue";

@Component({
    components: {UpdateIcon, DeleteIcon, Icon, DataTable, Modal}
})
export default class TemplateTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    dataProvider: Promise<IPaginatedList<Template>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest();
    Type: typeof TemplateType = TemplateType;

    labelTemplateImage: string | null = null;

    busy = false;

    @Getter tstpCache!: TimestampCache;

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("name"), key: "name", sortable: true},
            {label: this.$t("type"), key: "type", sortable: true},
            {label: this.$t("owner"), key: "isOwner", sortable: true},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    @Watch("tstpCache.templates")
    refresh() {
        if (!this.settingsCheck("order-sheets") && !this.query.filters.find(f => f.name === "type")) {
            this.query.filters.push({
                name: "type",
                op: FilterOperand.NotEquals,
                value: TemplateType.OrderSheet
            });
        }

        this.dataProvider = TemplateApi.find(this.clientId, this.query);
    }

    create() {
        this.$router.push({name: "createTemplate", params: {clientId: this.clientId.toString()}});
    }

    deleteTemplate(id: Guid) {
        TemplateApi.delete(this.clientId, id)
            .then(() => {
                this.$bvToast.toast(this.$t("template-deleted").toString(), SuccessToastSettings);
                this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-delete-template").toString(), ErrorToastSettings);

                throw err;
            });
    }

    async previewTemplate(id: Guid, type: TemplateType) {
        if (!this.busy) {
            this.busy = true;
            if (type === TemplateType.OrderSheet) {
                const data = await TemplateApi.previewPdf(this.clientId, id);
                this.busy = false;
                openBlobInNewTab(data as Blob);
            } else if (type === TemplateType.AddressLabel) {
                const data = await TemplateApi.previewImage(this.clientId, id);
                this.busy = false;
                this.labelTemplateImage = data as string;
            } else if (type === TemplateType.OrderImportedEmail || type === TemplateType.DispatchEmail) {
                try {
                    await TemplateApi.previewEmail(this.clientId, id);
                    this.$bvToast.toast("Preview email sent", SuccessToastSettings);
                } catch (err) {
                    this.$bvToast.toast("Failed to preview email", ErrorToastSettings);

                    throw err;
                }
            }
        }

    }

    duplicate(id: Guid) {
        TemplateApi.duplicate(this.clientId, id)
            .then(() => {
               this.refresh();
            })
        .catch(err => {
            this.$bvToast.toast("Failed to duplicate template", ErrorToastSettings);

            throw err;
        });
    }
}
