






import {Component, Model, Vue} from "vue-property-decorator";

const JoditEditor = () => import(/* webpackChunkName: "wysiwyg" */"@/components/JoditEditor.vue");

@Component({components: {JoditEditor}})
export default class Wysiwyg extends Vue {
    @Model("update:content", {type: String, required: true}) readonly content!: string;

    // @Getter getClient!: Client;

    get buttons(): any[] {
        return [
            "bold",
            "underline",
            "italic",
            "eraser",
            "|",
            "font",
            "fontsize",
            "paragraph",
            "brush",
            "|",
            "ul",
            "ol",
            "align",
            "outdent",
            "indent",
            "|",
            "table",
            "image",
            "link",
            "|",
            "source",
            "\n",
            "undo",
            "redo",
            "|",
            "hr",
            "fullsize",
            "|"];
    }

    get config(): any {
        return {
            tabIndex: 0
        };
    }

    get customButtons(): any[] {
        return [
            // {
            //     name: "insertLogo",
            //     icon: "image",
            //     tooltip: "Logo",
            //     exec: (editor: any) => {
            //        editor.selection.insertHTML(`<img title="Logo" alt="Logo" src="${this.getClient.logo}">`);
            //     }
            // }
        ];
    }

    get model(): string {
        return this.content;
    }

    set model(value: string) {
        this.$emit("update:content", value);
    }
}
