




import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ApexCharts from "apexcharts";
import {capitalise} from "@/api/Utils";

@Component
export default class DoubleGroupChart extends Vue {
    @Prop({type: String, required: true}) readonly primary!: string;
    @Prop({type: Array, required: true}) readonly groups!: string[];
    @Prop({type: Array, required: true}) readonly data!: Array<Record<string, string | number>>;
    @Prop({type: Array, required: true}) readonly dateFields!: string[];
    @Prop({type: String, required: true}) readonly type!: "bar"|"line"|"area";
    @Prop({type: String, default: undefined}) readonly title?: string;

    chart: any = null;

    get apexOptions() {
        const options: Record<string, any> = {
            series: [],
            xaxis: {
                categories: [],
            },
            chart: {
                height: "100%",
                type: this.type.toLowerCase(),
                animations: {
                    enabled: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                }
            },
            title: {
                text: this.title,
                align: "center",
                style: {
                    fontSize: "16px"
                }
            },
            noData: {
                text: "No Data",
                align: "center",
                style: {
                    fontSize: "24px"
                }
            },
            dataLabels: {
                enabled: false,
            },
            colors: [],
        };

        if (!this.data.length) {
            return options;
        }

        if (this.groups.length === 0) {
            options.series.push({
                name: capitalise(this.primary),
                data: [this.data[0]![this.primary]]
            });
            options.xaxis.categories.push(capitalise(this.primary));

            return options;
        }

        const firstGroupName = this.groups[0];
        const secondGroupName = this.groups[1];

        if (this.dateFields.includes(firstGroupName)) {
            options.xaxis.type = "datetime";
        }

        const uniqueFirstGroupValues = Array.from(new Set(this.data.map((dataRow) => {
            return dataRow[firstGroupName] ?? "None";
        })));

        const uniqueSecondGroupValues = Array.from(new Set(this.data.map((dataRow) => {
            return dataRow[secondGroupName] ?? "None";
        })));

        uniqueFirstGroupValues.forEach((value) => {
            options.xaxis.categories.push(value);
        });

        uniqueSecondGroupValues.forEach((secondGroupValue) => {
            options.series.push({
                name: secondGroupValue.toString(),
                data: uniqueFirstGroupValues.map((firstGroupValue) => {
                    const matchedEntry = this.data.find((entry) => {
                        return (entry[firstGroupName] ?? "None") === firstGroupValue
                        && (entry[secondGroupName] ?? "None") === secondGroupValue;
                    })!;

                    return matchedEntry[this.primary];
                })
            });
        });

        // Generate a distinct and unique colour for each series
        for (let i = 0; i < options.series.length; i += 1) {
            const hue = i * Math.trunc(360 / options.series.length);
            options.colors.push(`hsl(${hue}, 70%, 50%)`);
        }

        return options;
    }

    @Watch("apexOptions")
    rerender(newOptions) {
        if (!newOptions || !this.$refs.chartElement) {
            return;
        }
        this.chart?.destroy();
        this.chart = new ApexCharts(this.$refs.chartElement, newOptions);
        this.chart.render();
    }

    mounted() {
        this.rerender(this.apexOptions);
    }

    beforeDestroy() {
        this.chart?.destroy();
    }
}
