


































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {Guid} from "guid-typescript";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    IFilteredQueryRequest,
    IPaginatedList,
    SuccessToastSettings
} from "@/api/Utils";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {Getter} from "vuex-class";
import {TimestampCache} from "@/store/cache";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import {SkuMap, SkuMapApi} from "@/api/v1/SkuMapApi";
import OrderSourceLogo from "@/components/OrderSourceLogo.vue";

@Component({components: {DeleteIcon, DataTable, OrderSourceLogo}})
export default class SkuMapTable extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    @Getter tstpCache!: TimestampCache;

    dataProvider: Promise<IPaginatedList<SkuMap>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest(1, 25, "id");

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("order-source-type"), key: "orderSourceType", sortable: true},
            {label: this.$t("original-sku"), key: "originalSku", sortable: true},
            {label: this.$t("mapped-sku"), key: "mappedSku", sortable: true},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    @Watch("tstpCache.skuMaps")
    refresh() {
        this.dataProvider = SkuMapApi.find(this.clientId, this.query);
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    deleteSkuMap(id: Guid) {
        SkuMapApi.delete(this.clientId, id)
            .then(() => {
                this.$bvToast.toast(this.$t("sku-map-deleted").toString(), SuccessToastSettings);
                this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-delete-sku-map").toString(), ErrorToastSettings);

                throw err;
            });
    }

    create() {
        this.$router.push({name: "createSkuMap"});
    }
}
