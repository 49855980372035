import {IPaginatedList, QueryRequest} from "@/api/Utils";
import {Guid} from "guid-typescript";
import {OrderSourceType} from "@/api/v1/OrderSourceApi";
import {deleteRequest, getRequest, postRequest} from "@/api/HttpMethods";

export const SkuMapApi = {
    create(clientId: Guid, skuMap: SkuMap): Promise<SkuMap> {
        return postRequest(getBaseUrl(clientId), skuMap);
    },
    find(clientId: Guid, query: QueryRequest): Promise<IPaginatedList<SkuMap>> {
        return getRequest(getBaseUrl(clientId), query);
    },
    delete(clientId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${id}`);
    }
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/sku-maps`;
}

export interface SkuMap {
    id: Guid;
    clientId: Guid;
    orderSourceType?: OrderSourceType;
    originalSku: string;
    mappedSku: string;
}

export function emptySkuMap(): SkuMap {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        orderSourceType: OrderSourceType.Ebay,
        originalSku: "",
        mappedSku: ""
    };
}
