














































































import {Component, Prop, Vue} from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import {Guid} from "guid-typescript";
import {Address, AddressApi, AddressType, emptyAddress} from "@/api/v1/AddressApi";
import {Action} from "vuex-class";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";
import Icon from "@/components/helpers/Icon.vue";
import CountrySelect from "@/components/helpers/CountrySelect.vue";

@Component({components: {Modal, Icon, CountrySelect}})
export default class CreateOrUpdateAddress extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, default: null}) readonly addressId!: Guid | null;

    @Action invalidateAddresses!: () => any;

    address: Address = emptyAddress(AddressType.Collection);

    done() {
        this.$emit("done");
    }

    submit() {
        if (this.addressId) {
            AddressApi.update(this.clientId, this.addressId, this.address)
                .then(() => {
                    this.$parent.$bvToast.toast(this.$t("address-updated").toString(), SuccessToastSettings);
                    this.done();
                    this.invalidateAddresses();
                })
                .catch(err => {
                    this.$parent.$bvToast.toast(this.$t("failed-update-address").toString(), ErrorToastSettings);

                    throw err;
                });
        } else {
            AddressApi.create(this.clientId, this.address)
                .then(() => {
                    this.$parent.$bvToast.toast(this.$t("address-created").toString(), SuccessToastSettings);
                    this.done();
                    this.invalidateAddresses();
                })
                .catch(err => {
                    this.$parent.$bvToast.toast(this.$t("failed-create-address").toString(), ErrorToastSettings);

                    throw err;
                });
        }
    }

    created() {
        if (this.addressId) {
            AddressApi.findById(this.clientId, this.addressId)
                .then(data => {
                    if (data)
                        this.address = data;
                });
        }
    }
}
