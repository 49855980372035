


































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {Guid} from "guid-typescript";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    IFilteredQueryRequest,
    IPaginatedList,
    SuccessToastSettings
} from "@/api/Utils";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {Getter} from "vuex-class";
import {TimestampCache} from "@/store/cache";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import OrderSourceLogo from "@/components/OrderSourceLogo.vue";
import {CourierMap, CourierMapApi} from "@/api/v1/CourierMapApi";

@Component({components: {DeleteIcon, DataTable, OrderSourceLogo}})
export default class CourierMapTable extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    @Getter tstpCache!: TimestampCache;

    dataProvider: Promise<IPaginatedList<CourierMap>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest(1, 25, "id");

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("order-source-type"), key: "orderSourceType", sortable: true},
            {label: this.$t("original-courier"), key: "originalCourier", sortable: true},
            {label: this.$t("mapped-courier"), key: "mappedCourier", sortable: true},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    @Watch("tstpCache.courierMaps")
    refresh() {
        this.dataProvider = CourierMapApi.find(this.clientId, this.query);
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    deleteMap(id: Guid) {
        CourierMapApi.delete(this.clientId, id)
            .then(() => {
                this.$bvToast.toast(this.$t("courier-map-deleted").toString(), SuccessToastSettings);
                this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-delete-courier-map").toString(), ErrorToastSettings);

                throw err;
            });
    }

    create() {
        this.$router.push({name: "createCourierMap"});
    }
}
