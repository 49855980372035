
















































import {Component, Prop, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {Guid} from "guid-typescript";
import {
    emptyPaginatedList,
    FilteredQueryRequest,
    FilterOperand,
    IFilteredQueryRequest,
    IPaginatedList
} from "@/api/Utils";
import {Address, AddressApi, AddressType} from "@/api/v1/AddressApi";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {Getter} from "vuex-class";
import {TimestampCache} from "@/store/cache";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import Check from "@/components/helpers/Check.vue";
import {displayCountry} from "@/api/CountryApi";
import Helper from "@/components/helpers/Helper.vue";

@Component({components: {Check, UpdateIcon, DataTable}})
export default class AddressTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    @Getter tstpCache!: TimestampCache;

    dataProvider: Promise<IPaginatedList<Address>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest();
    typeFilterValue: AddressType | null = null;

    country(country: string): string {
        return displayCountry(country);
    }

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("system-name"), key: "systemName", sortable: true},
            {label: this.$t("type"), key: "type", sortable: false},
            {label: this.$t("name"), key: "name", sortable: true},
            {label: this.$t("address.city"), key: "city", sortable: true},
            {label: this.$t("address.postcode"), key: "postcode", sortable: true},
            {label: this.$t("address.country"), key: "country", sortable: true},
            {label: this.$t("default"), key: "defaultAddress", sortable: true},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    @Watch("tstpCache.addresses")
    refresh() {
        if (this.query.filters.find(f => f.name === "type") === undefined && this.typeFilterValue !== null) {
            this.query.filters.push({
                name: "type",
                op: FilterOperand.Equals,
                value: this.typeFilterValue
            });
        }
        this.dataProvider = AddressApi.find(this.clientId, this.query);
    }

    @Watch("typeFilterValue")
    onTubeFilterUpdate() {
        const typeFilter = this.query.filters.find(f => f.name === "type");
        if (typeFilter) {
            if (this.typeFilterValue === null) {
                this.query.filters = this.query.filters.filter(f => f.name !== "type");
            }
            else typeFilter.value = this.typeFilterValue;
        }

        this.refresh();
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    create() {
        this.$router.push({name: "createAddress"});
    }
}
