


















































































































import {Component, Prop} from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import {Guid} from "guid-typescript";
import {Action} from "vuex-class";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";
import {OrderSourceType} from "@/api/v1/OrderSourceApi";
import {CourierMap, CourierMapApi, emptyCourierMap} from "@/api/v1/CourierMapApi";
import Helper from "@/components/helpers/Helper.vue";

@Component({components: {Modal}})
export default class CreateCourierMap extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    @Action invalidateCourierMaps!: () => any;

    courierMap: CourierMap = emptyCourierMap();
    OrderSourceType: typeof OrderSourceType = OrderSourceType;

    done() {
        this.$emit("done");
    }

    submit() {
        CourierMapApi.create(this.clientId, this.courierMap)
            .then(() => {
                this.$parent.$bvToast.toast(this.$t("courier-map-created").toString(), SuccessToastSettings);
                this.done();
                this.invalidateCourierMaps();
            })
            .catch(err => {
                this.$parent.$bvToast.toast(this.$t("failed-create-courier-map").toString(), ErrorToastSettings);

                throw err;
            });
    }
}
