




































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {Guid} from "guid-typescript";
import {TimestampCache} from "@/store/cache";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    IFilteredQueryRequest,
    IPaginatedList,
    SuccessToastSettings
} from "@/api/Utils";
import {Getter} from "vuex-class";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import Check from "@/components/helpers/Check.vue";
import {ApiToken, TokenApi} from "@/api/v1/TokenApi";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import Modal from "@/components/Modal.vue";

@Component({components: {Check, UpdateIcon, DeleteIcon, DataTable, Modal}})
export default class ApiTokenTable extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    @Getter tstpCache!: TimestampCache;

    dataProvider: Promise<IPaginatedList<ApiToken>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest();
    deleting: ApiToken | null = null;

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("name"), key: "name", sortable: true},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    @Watch("tstpCache.apiTokens")
    refresh() {
        this.dataProvider = TokenApi.find(this.clientId, this.query);
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    create() {
        this.$router.push({name: "createApiToken"});
    }

    confirmDelete(item: ApiToken) {
        this.deleting = item;
    }

    deleteToken() {
        if (this.deleting) {
            TokenApi.delete(this.clientId, this.deleting.id)
                .then(() => {
                    this.$bvToast.toast(this.$t("api-token-deleted").toString(), SuccessToastSettings);
                    this.deleting = null;
                    this.refresh();
                })
                .catch(err => {
                    this.$bvToast.toast(this.$t("failed-delete-api-token").toString(), ErrorToastSettings);

                    throw err;
                });
        }
    }

    closeModal() {
        this.deleting = null;
    }
}
