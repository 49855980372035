import {IPaginatedList, QueryRequest} from "@/api/Utils";
import {Guid} from "guid-typescript";
import {OrderSourceType} from "@/api/v1/OrderSourceApi";
import {deleteRequest, getRequest, postRequest} from "@/api/HttpMethods";

export const CourierMapApi = {
    create(clientId: Guid, map: CourierMap): Promise<CourierMap> {
        return postRequest(getBaseUrl(clientId), map);
    },
    find(clientId: Guid, query: QueryRequest): Promise<IPaginatedList<CourierMap>> {
        return getRequest(getBaseUrl(clientId), query);
    },
    delete(clientId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${id}`);
    }
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/courier-maps`;
}

export interface CourierMap {
    id: Guid;
    clientId: Guid;
    orderSourceType?: OrderSourceType;
    originalCourier: string;
    mappedCourier: string;
}

export function emptyCourierMap(): CourierMap {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        orderSourceType: OrderSourceType.Ebay,
        originalCourier: "",
        mappedCourier: ""
    };
}
