












































import {Component, Prop, Vue} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {
    Carrier,
    CarrierApi,
    CarrierAuthSettings,
    emptyCarrier,
    emptyCarrierAuthSettings,
    CarrierType
} from "@/api/v1/CarrierApi";
import {ErrorToastSettings} from "@/api/Utils";

@Component
export default class AuthCarrier extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, default: null}) readonly carrierId!: Guid | null;

    carrier: Carrier = emptyCarrier();
    authSettings: CarrierAuthSettings = emptyCarrierAuthSettings();

    inProgress = false;

    CarrierType: typeof CarrierType = CarrierType;

    stateKey = "carrier_auth_state";

    get authoriseUrl(): string {
        let result = "";
        if (this.carrierId) {
            const startOfUrl = `${location.protocol}//${location.hostname}${location.port ? ":" + location.port : ""}`;
            const redirectUri = `${encodeURI(`${startOfUrl}/settings/carriers/auth`)}`;

            const stateObject: State = {
                carrierId: this.carrierId,
                nonce: Guid.create().toString()
            };

            const state = stateObject.nonce;
            window.localStorage.setItem(this.stateKey, JSON.stringify(stateObject));

            if (this.carrier.type === CarrierType.Parcel2Go) {
                if (this.authSettings.p2g.clientId === "") {
                    //TODO handle error
                    // this.$router.push({name: "carriers"});
                } else {
                    const baseUrl =
                        `https://${this.authSettings.p2g.sandbox ? "sandbox" : "www"}.parcel2go.com/auth/connect/authorize`;

                    const params = new URLSearchParams();
                    params.append("response_type", "code");
                    params.append("scope", this.authSettings.p2g.scopes);
                    params.append("client_id", this.authSettings.p2g.clientId);
                    params.append("state", state);
                    params.append("redirect_uri", redirectUri);

                    result = `${baseUrl}?${params.toString()}`;
                }
            } else if (this.carrier.type === -1) {
                //Do nothing
            } else {
                this.$router.push({name: "carriers"});
            }
        }
        return result;
    }

    async created() {
        let carrierId: Guid | null = this.carrierId;
        const params = this.$route.query;
        if (!carrierId) {
            const stateParam = params["state"] as string | (string | null)[] | undefined;
            let nonce = "";
            const storedStateString = window.localStorage.getItem(this.stateKey);
            if (storedStateString) {
                window.localStorage.removeItem(this.stateKey);
                const storedState = JSON.parse(storedStateString) as State;
                if (stateParam !== "" && stateParam !== [] && stateParam !== undefined) {
                    nonce = stateParam as string;
                }

                if (nonce && storedState.nonce && nonce === storedState.nonce) {
                    carrierId = storedState.carrierId;
                }
            }
        }
        if (carrierId) {
            try {
                this.carrier = await CarrierApi.findById(this.clientId, carrierId);

                this.authSettings = await CarrierApi.getAuthSettings(this.clientId);
                const code = params["code"] as string | (string | null)[] | undefined;
                if (code !== "" && code !== [] && code !== undefined) {
                    const scope = decodeURIComponent(params["scope"] as string);
                    if (this.carrier.type === CarrierType.Parcel2Go) {
                        if (scope !== "public-api payment offline_access") {
                            //TODO improve this
                            this.$parent.$bvToast.toast(this.$t("failed-authorise").toString(), ErrorToastSettings);
                            await this.$router.push({name: "carriers"});
                        }
                    }
                    this.carrier.secret = code as string;
                    this.inProgress = true;
                    CarrierApi.auth(this.clientId, carrierId, {code: this.carrier.secret})
                        .then(() => {
                            this.inProgress = false;
                            this.$router.push({name: "carriers"});
                        })
                        .catch(err => {
                            this.$parent.$bvToast.toast(this.$t("failed-authorise").toString(), ErrorToastSettings);
                            this.inProgress = false;

                            throw err;
                        });
                }
            } catch (err) {
                this.$parent.$bvToast.toast(this.$t("failed-authorise").toString(), ErrorToastSettings);
                await this.$router.push({name: "carriers"});

                throw err;
            }
        } else {
            if (!this.carrierId) {
                this.$parent.$bvToast.toast(this.$t("failed-authorise").toString(), ErrorToastSettings);
            }
            await this.$router.push({name: "carriers"});
        }
    }
}

interface State {
    carrierId: Guid;
    nonce: string;
}
