import {IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";
import {deleteRequest, getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {Guid} from "guid-typescript";

export const TokenApi = {
    create(clientId: Guid, token: ApiToken): Promise<ApiTokenWithSecret> {
        return postRequest(getBaseUrl(clientId), token);
    },
    find(clientId: Guid, queryRequest: IFilteredQueryRequest): Promise<IPaginatedList<ApiToken>> {
        return getRequest(getBaseUrl(clientId), queryRequest);
    },
    findById(clientId: Guid, id: Guid): Promise<ApiToken> {
        return getRequest(`${getBaseUrl(clientId)}/${id}`);
    },
    update(clientId: Guid, id: Guid, product: ApiToken): Promise<void> {
        return putRequest(`${getBaseUrl(clientId)}/${id}`, product);
    },
    delete(clientId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${id}`);
    }
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/api-tokens`;
}

export interface ApiToken {
    clientId: Guid;
    id: Guid;
    name: string;
}

export interface ApiTokenWithSecret {
    clientId: Guid;
    id: Guid;
    name: string;
    secret: string;
}

export function emptyApiToken(): ApiToken {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        name: ""
    };
}
