











































import {Component, Prop, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    IFilteredQueryRequest,
    IPaginatedList,
    SuccessToastSettings
} from "@/api/Utils";
import {OrderSource, OrderSourceApi, OrderSourceType} from "@/api/v1/OrderSourceApi";
import {Guid} from "guid-typescript";
import {Getter} from "vuex-class";
import {TimestampCache} from "@/store/cache";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import OrderSourceLogo from "@/components/OrderSourceLogo.vue";
import Icon from "@/components/helpers/Icon.vue";
import {UserIdentity} from "@/store/types";
import Helper from "@/components/helpers/Helper.vue";
import Modal from "@/components/Modal.vue";

@Component({components: {Icon, OrderSourceLogo, DeleteIcon, UpdateIcon, DataTable, Modal}})
export default class OrderSourceTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    @Getter tstpCache!: TimestampCache;
    @Getter getIdentity!: UserIdentity;

    dataProvider: Promise<IPaginatedList<any>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest();
    deleting: OrderSource | null = null;

    OrderSourceType: typeof OrderSourceType = OrderSourceType;

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("name"), key: "name", sortable: true},
            {label: this.$t("type"), key: "type", sortable: true},
            {label: "ID", key: "id", sortable: true},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    reauthTo(id: Guid) {
        return {
            name: "authOrderSource",
            params: {
                clientId: this.clientId.toString()
            },
            query: {
                orderSourceId: id.toString()
            }
        };
    }

    isOauthSource(type: OrderSourceType): boolean {
        return type === OrderSourceType.Amazon || type === OrderSourceType.Ebay || type === OrderSourceType.Shopify
            || type === OrderSourceType.Ekm || type === OrderSourceType.BigCommerce
            || type === OrderSourceType.Squarespace || type === OrderSourceType.Etsy;
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    create() {
        this.$router.push({name: "selectOrderSourceType"});
    }

    @Watch("tstpCache.orderSources")
    refresh() {
        this.dataProvider = OrderSourceApi.find(this.clientId, this.query);
    }

    confirmDelete(item: OrderSource) {
        this.deleting = item;
    }

    deleteOrderSource() {
        if (this.deleting) {
            OrderSourceApi.delete(this.clientId, this.deleting.id)
                .then(() => {
                    this.$bvToast.toast(this.$t("order-source-deleted").toString(), SuccessToastSettings);
                    this.deleting = null;
                    this.refresh();
                })
                .catch(err => {
                    this.$bvToast.toast(this.$t("failed-delete-order-source").toString(), ErrorToastSettings);

                    throw err;
                });
        }
    }

    closeModal() {
        this.deleting = null;
    }
}
